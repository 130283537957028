import React, { useState, useEffect, useRef } from "react";
import Portal from "components/Portal";
import { ReactComponent as IconX } from "svg/x.svg";

export const RadioButton = (props) => {
  const { group, option, isChecked, onChange } = props;

  return (
    <div className="filter-modal_radio-buttons">
      <label>
        {option.label}
        <input type="radio" value={option.value} checked={isChecked} name={group} onChange={onChange.bind(this, option)} />
        <span className="radio-button"></span>
      </label>
    </div>
  );
};

const FilterModal = (props) => {
  const { button, title, group, contentClassNames, curValue, options, loading, onApply, onCancel, onToggleModal } = props;

  const [showModal, setShowModal] = useState(false);
  const [scrollableBelow, setScrollableBelow] = useState(false);
  const [optionToApply, setOptionToApply] = useState(curValue);
  const content = useRef();

  useEffect(() => {
    if (!optionToApply) {
      setOptionToApply(curValue);
    }
  }, [curValue]);

  const toggleModal = (e) => {
    setShowModal(!showModal);
    onToggleModal && onToggleModal(e);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onCancelHook = () => {
    onCancel && onCancel();
    closeModal();
  };

  const onApplyHook = () => {
    if (onApply) {
      options ? onApply(optionToApply) : onApply();
    }
    closeModal();
  };

  const listOptions = (options, curValue) => {
    return (
      <div className="filter-modal_options">
        {options.map((option) => {
          const isChecked = curValue && option.value === optionToApply.value;

          return (
            <RadioButton key={option.value} group={group} option={option} isChecked={isChecked} onChange={setOptionToApply} />
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      {button && (
        <div className="select_modal">
          <div className={`select_modal__toggle`} onClick={toggleModal}>
            {button}
          </div>
        </div>
      )}
      {showModal && (
        <Portal>
          <div className="filter-modal">
            <div className="filter-modal_header">
              <div>{title}</div>
              <div className="filter-modal_close" onClick={onCancelHook}>
                <IconX />
              </div>
            </div>
            <div ref={content} className={`filter-modal_content ${contentClassNames}`}>
              {loading ? (
                <div style={{ padding: "20px" }}>
                  <div className="spinthatshit-loader">
                    <div className="spinner"></div>
                  </div>
                </div>
              ) : options ? (
                listOptions(options, curValue)
              ) : (
                props.children
              )}
            </div>
            <div className={`filter-modal_buttons ${scrollableBelow ? "fade-content" : ""}`}>
              <div onClick={onCancelHook} className="btn-transparent filter-modal_cancel">
                Cancel
              </div>
              <div onClick={onApplyHook} className="btn-blue filter-modal_apply">
                Apply
              </div>
            </div>
          </div>
        </Portal>
      )}
    </React.Fragment>
  );
};

export default FilterModal;

// import React, { Component, Fragment } from "react";
// import Portal from "components/Portal";
// import ReactSVG from "react-svg";
//
// const RadioButton = (props) => {
//   const {
//     group,
//     option,
//     isChecked,
//     onChange
//   } = props;
//
//   return (
//     <div className="filter-modal_radio-buttons">
//       <label>
//         {option.label}
//         <input
//           type="radio"
//           value={option.value}
//           checked={isChecked}
//           name={group}
//           onChange={onChange.bind(this, option)}
//         />
//         <span className="radio-button"></span>
//       </label>
//     </div>
//   )
// }
//
// export default class FilterModal extends Component {
//   content = React.createRef();
//
//   state = {
//     showModal: false,
//     scrollableBelow: false
//   }
//
//   componentWillUnmount() {
//     if (this.content && this.content.current) {
//       this.content.current.removeEventListener("scroll", this.onContentScroll);
//     }
//
//     document.documentElement.removeAttribute("style");
//     document.body.removeAttribute("style");
//   }
//
//   componentDidUpdate(prevProps, prevState) {
//     if (!prevState.showModal && this.state.showModal) {
//       if (this.content && this.content.current) {
//         this.onContentScroll();
//         this.content.current.addEventListener("scroll", this.onContentScroll);
//       }
//
//       // Disable scroll on body while modal is open
//       // document.documentElement.setAttribute("style", "position: fixed; overflow: hidden");
//       // document.body.setAttribute("style", "position: fixed; overflow: hidden");
//     }
//
//     if (prevState.showModal && !this.state.showModal) {
//       if (this.content && this.content.current) {
//         this.content.current.removeEventListener("scroll", this.onContentScroll);
//       }
//
//       // document.documentElement.removeAttribute("style");
//       // document.body.removeAttribute("style");
//     }
//   }
//
//   toggleModal = (e) => {
//     this.setState({showModal: !this.state.showModal});
//   }
//
//   openModal = () => {
//     this.setState({showModal: true});
//   }
//
//   closeModal = () => {
//     this.setState({showModal: false});
//   }
//
//   onCancelHook = () => {
//     this.props.onCancel && this.props.onCancel();
//
//     this.closeModal();
//   }
//
//   onApplyHook = () => {
//     const {
//       options,
//       curValue,
//       onApply
//     } = this.props;
//
//     if (onApply) {
//       if (options) {
//         onApply(curValue);
//       } else {
//         onApply();
//       }
//     }
//
//     this.closeModal();
//   }
//
//   onContentScroll = (e) => {
//     const { scrollableBelow } = this.state;
//     const { scrollTop, scrollHeight, offsetHeight } = this.content.current;
//
//     if (scrollTop + offsetHeight < scrollHeight) {
//       !scrollableBelow && this.setState({ scrollableBelow: true});
//     } else {
//       scrollableBelow && this.setState({ scrollableBelow: false});
//     }
//     // console.log(e.target.scrollTop, e.target.offsetHeight, e.target.scrollHeight);
//   }
//
//   listOptions = (options, curValue) => {
//     const { group, onChange } = this.props;
//
//     return (
//       <div className="filter-modal_options">
//         {
//           options.map(option => {
//             const isChecked = curValue && (option.value === curValue.value);
//
//             return (
//               <RadioButton
//                 key={option.value}
//                 group={group}
//                 option={option}
//                 isChecked={isChecked}
//                 onChange={onChange}
//               />
//             )
//           })
//         }
//       </div>
//     )
//   }
//
//   render() {
//     const {
//       showModal,
//       scrollableBelow
//     } = this.state;
//
//     const {
//       button,
//       title,
//       contentClassNames = "",
//       options,
//       curValue,
//       onChange,
//       onApply,
//       onCancel
//     } = this.props;
//
//     return (
//       <Fragment>
//         {
//           button &&
//             <div className="select_modal">
//               <div
//                 className={`select_modal__toggle`}
//                 onClick={this.toggleModal}
//               >
//                 {button}
//               </div>
//             </div>
//         }
//         {
//           showModal &&
//             <Portal>
//               <div className="filter-modal">
//                 <div className="filter-modal_header">
//                   <div>{title}</div>
//                   <div className="filter-modal_close" onClick={this.onCancelHook}>
//                     <ReactSVG wrapperClassName="react-svg"
//                       path={"https://static.bigbrain.gg/assets/ugg/icons/x.svg"}
//                     />
//                   </div>
//                 </div>
//                 <div ref={this.content} className={`filter-modal_content ${contentClassNames}`}>
//                   {
//                     options
//                     ? this.listOptions(options, curValue)
//                     : this.props.children
//                   }
//                 </div>
//                 <div className={`filter-modal_buttons ${scrollableBelow ? "fade-content" : ""}`}>
//                   <div onClick={this.onCancelHook} className="btn-transparent filter-modal_cancel">
//                     Cancel
//                   </div>
//                   <div onClick={this.onApplyHook} className="btn-light-blue filter-modal_apply">
//                     Apply
//                   </div>
//                 </div>
//               </div>
//             </Portal>
//         }
//       </Fragment>
//     )
//   }
// }
