import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import FilterModal from "components/FilterManager/FilterModal";
import { ReactComponent as TriangleArrowDown } from "svg/triangle-arrow-down.svg";

export default class DefaultSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionToApply: null,
      selectedOption: null,
      isModalOpen: false,
    };

    this.findElement = this.findElement.bind(this);
    this.closeMenuRef = React.createRef();
  }

  onOptionChange = (optionToApply) => {
    this.setState({ optionToApply });
  };

  onApplyChange = (selectedOption) => {
    const { onChange } = this.props;

    this.setState({
      optionToApply: null,
      selectedOption,
      isModalOpen: false,
    });
    onChange(selectedOption);
  };

  handleChange = (selectedOption) => {
    this.onApplyChange(selectedOption);
  };

  findElement = (value, options) => {
    let element = null;

    if (options) {
      options.map((valueLable) => {
        if (valueLable.value == value) {
          element = valueLable;
        }
      });
    }

    return element;
  };

  closeDropdown = (e) => {
    if (this.closeMenuRef) {
      this.closeMenuRef.select.blur();
    }
  };

  closeModal = (e) => {
    this.setState({ optionToApply: null });
  };

  render() {
    const { optionToApply, isModalOpen } = this.state;
    const {
      title,
      group,
      className,
      options,
      placeholder,
      onChange,
      value,
      tabIndex,
      isSearchable,
      maxHeight,
      width,
      formatOptionLabel,
      onMenuClose,
      isMobile,
      isDisabled,
      error,
      customButton,
      fontSize,
    } = this.props;

    let selectedOption = this.findElement(value, options);

    const customStyles = {
      clearIndicator: () => {},
      container: () => {},
      control: () => {},
      dropdownIndicator: () => {},
      group: () => {},
      groupHeading: () => {},
      indicatorsContainer: () => {},
      indicatorSeparator: () => {},
      input: () => ({
        fontSize,
      }),
      loadingIndicator: () => {},
      loadingMessage: () => {},
      menuPortal: () => {},
      multiValue: () => {},
      multiValueLabel: () => {},
      multiValueRemove: () => {},
      noOptionsMessage: () => {},
      option: () => ({
        fontSize,
      }),
      placeholder: () => ({
        fontSize,
      }),
      singleValue: () => ({
        fontSize,
      }),
      valueContainer: () => ({
        fontSize,
      }),
      menu: (base) => ({
        maxHeight: maxHeight || 500,
        zIndex: 10,
        width: "auto",
        borderRadius: "4px",
        backgroundColor: "#25254b",
        overflow: "hidden",
        position: "absolute",
        top: "100%",
        marginBottom: "8px",
        marginTop: "8px",
        cursor: "pointer",
      }),
      menuList: (base) => ({
        width: width,
        minWidth: width,
        zIndex: 10,
        fontFamily: "Helvetica, sans-serif",
        maxHeight: maxHeight || 500,
        overflowY: "auto",
        paddingTop: "0px",
        paddingBottom: "0px",
      }),
    };

    if (error) {
      customStyles = Object.assign(customStyles, {
        control: (provided, state) => ({
          ...provided,
          borderColor: "red !important",
        }),
      });
    }

    const selectComponent = (
      <Fragment>
        {
          // Need this because clicks don't trigger on parent <div>
          isMobile && <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 }}></div>
        }
        <Select
          placeholder={placeholder || "Select"}
          formatOptionLabel={formatOptionLabel}
          components={{ DropdownIndicator: () => <TriangleArrowDown className="custom-indicator" /> }}
          styles={customStyles}
          className={`default-select ${className}`}
          classNamePrefix={`default-select`}
          options={options}
          onChange={this.handleChange}
          value={selectedOption}
          isSearchable={isSearchable ? true : false}
          defaultValue={null}
          isClearable={false}
          onMenuClose={onMenuClose}
          isDisabled={isMobile || isDisabled}
          tabIndex={tabIndex}
          ref={(input) => (this.closeMenuRef = input)}
        />
      </Fragment>
    );

    return (
      <div style={{ position: "relative" }}>
        {isMobile ? (
          <FilterModal
            button={customButton || selectComponent}
            title={title}
            group={group}
            options={options}
            curValue={optionToApply || selectedOption}
            onChange={this.onOptionChange}
            onApply={this.onApplyChange}
            onCancel={this.closeModal}
          />
        ) : (
          selectComponent
        )}
      </div>
    );
  }
}
